import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/_work/github_runners_front-2/42next-app-client/42next-app-client/node_modules/.pnpm/next@14.2.10_@babel+core@7.24.7_@opentelemetry+api@1.8.0_@playwright+test@1.45.0_react-dom@18_6awnvakeoaqgq7dbsrps4jcfou/node_modules/next/dist/client/components/render-from-template-context.js");
